import Bar from '../../layout/Bar'
import DatePicker from 'react-datepicker'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../../AppContext'
import { useContext, useState, useEffect, memo } from 'react'

export const Graph = memo(({ graph, filter }) => {
    return <Bar data={{ labels: graph?.map(e => e.label),
        datasets: [{
            backgroundColor: '#238b44',
            data: graph?.map(e => e.data),
            label: filter == 'price' ? 'Valor' : 'Kg',
            datalabels: { display: true, color: '#fff', font: { size: 10 }, rotation: -90, formatter: d => d.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }
        }]
    }} />;
});
const Dashboard = () => {
    const [ graph1, setGraph1 ] = useState(null)
    const [ graph2, setGraph2 ] = useState(null)
    const [ tables, setTables ] = useState(null)
    const [ vaults, setVaults ] = useState(null)
    const [ filter, setFilter ] = useState({mes_type: '', mes_vault: '', mes: '', type: '', period_vault: '', type_period: ''})
    const { user, setLoading, hostBase, dateFormat } = useContext(AppContext)

    const updateField = (e) => {
        let _filter = {...filter}
        _filter[e.target.name] = e.target.value
        setFilter(_filter)
    }
    const updateGraph1 = (e) => {
        let _filter = {...filter}
        _filter[e.target.name] = e.target.value
        setFilter(_filter)
        let formData = new FormData()
        for(let key in _filter)
            formData.append(key, _filter[key])
        const token = localStorage.getItem('userToken')
        fetch(`${hostBase}/api/user/graphs/month/?token=${token}`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then(data => setGraph1(data))
            .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
    }
    const updateGraph2 = (e) => {
        let _filter = {...filter}
        _filter[e.target.name] = e.target.value
        setFilter(_filter)
        let formData = new FormData()
        for(let key in _filter)
            formData.append(key, _filter[key])
        const token = localStorage.getItem('userToken')
        fetch(`${hostBase}/api/user/graphs/period/?token=${token}`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then(data => setGraph2(data))
            .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
    }
    const updateTables = (e) => {
        let _filter = {...filter}
        _filter[e.target.name] = e.target.value
        setFilter(_filter)
        let formData = new FormData()
        for(let key in _filter)
            formData.append(key, _filter[key])
        const token = localStorage.getItem('userToken')
        fetch(`${hostBase}/api/user/graphs/tables/?token=${token}`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then(data => setTables(data))
            .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
    }

    useEffect(() => {
        setLoading(true)
        let formData = new FormData()
        const token = localStorage.getItem('userToken')
        fetch(`${hostBase}/api/user/graphs/?token=${token}`, { method: 'POST', body: formData })
            .then(res => res.json())
            .then(data => {
                setGraph1(data)
                setGraph2(data)
                setTables(data)
            })
            .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
            .finally(() => {
                fetch(`${hostBase}/api/vault/list/?token=${token}`)
                    .then(res => res.json())
                    .then((data) => {
                        if(data.length !== 0)
                            setVaults(data)
                        else
                            toast.error('No se encontró ninguna bodega.', { theme: "colored", transition: Flip })
                    })
                    .catch((e) => { toast.error('Ocurrió un error al intentar traer el listado de bodegas.', { theme: "colored", transition: Flip }) })
                    .finally(() => setLoading(false))
            })
    }, [])

    return <div className="dashboard">
        <div className="w-50">
            <h2>
                Total mes <small>({(graph1?.kg ? graph1?.kg : 0)+' Kg'})</small><br />
                <select name="mes_type" value={filter?.mes_type || ''} onChange={updateGraph1}>
                    <option value="">Todo tipo</option>
                    <option value="1">ACU</option>
                    <option value="2">Trampa grasa</option>
                </select>
                <select name="mes_vault" value={filter?.mes_vault || ''} onChange={updateGraph1} style={{maxWidth: '160px'}}>
                    <option value="">Todas las bodegas</option>
                    {vaults?.map(vault =>
                        <option value={vault?.vid}>{vault?.name}</option>
                    )}
                </select>
                <select name="mes" value={filter?.mes || ''} onChange={updateGraph1}>
                    <option value="">Kg</option>
                    <option value="price">Valor</option>
                    <option value="agent">Vendedor</option>
                    <option value="type">Tipo de cliente</option>
                </select>
            </h2>
            <Graph graph={graph1?.month} filter={filter?.mes} />
        </div>
        <div className="w-50">
            <h2>
                Último periodo<br />
                <select name="type" value={filter?.type || ''} onChange={updateGraph2}>
                    <option value="">Todo tipo</option>
                    <option value="1">ACU</option>
                    <option value="2">Trampa grasa</option>
                </select>
                <select name="period_vault" value={filter?.period_vault || ''} onChange={updateGraph2} style={{maxWidth: '160px'}}>
                    <option value="">Todas las bodegas</option>
                    {vaults?.map(vault =>
                        <option value={vault?.vid}>{vault?.name}</option>
                    )}
                </select>
                <select name="type_period" value={filter?.type_period || ''} onChange={updateGraph2}>
                    <option value="">Kg</option>
                    <option value="price">Valor</option>
                    <option value="agent">Vendedor</option>
                    <option value="type">Tipo de cliente</option>
                </select>
                <select name="periodo" value={filter?.periodo || ''} onChange={updateGraph2}>
                    <option value="">Trimestre</option>
                    <option value="semester">Semestre</option>
                    <option value="year">Año</option>
                </select>
            </h2>
            <Graph graph={graph2?.period} filter={filter?.type_period} />
        </div>
        <div className="w-100">
            <h2 className="filters">
                Exportar información
                <select name="export" value={filter?.export || ''} onChange={updateField}>
                    <option value="1">Mes actual</option>
                    <option value="2">Mes pasado</option>
                    <option value="3">Mes antepasado</option>
                    <option value="x">Rango</option>
                </select>
                {filter?.export == 'x' && <DatePicker placeholderText="Rango de fechas" onChange={date => setFilter({...filter, from: date[0], to: date[1]})} selectsRange selected={filter?.from} startDate={filter?.from} endDate={filter?.to} /> || null}
                <select name="export_agent" value={filter?.export_agent || ''} onChange={updateTables}>
                    <option value="">TODOS</option>
                    {tables?.agents.map(a => <option key={a.agid} value={a.agid}>{a.name.split(' ').slice(0,2).join('')}</option>)}
                </select>
            </h2>
            {user?.type < 1 ? <a href={`${hostBase}/api/user/export/?type=clients_all&token=${localStorage.getItem('userToken')}`} target="_blank" className="button min">Todos los clientes</a> : <></>}
            <a href={`${hostBase}/api/user/export/?type=clients&months=${filter?.export || 1}&from=${dateFormat(filter?.from) || ''}&to=${dateFormat(filter?.to) || ''}&agent=${filter?.export_agent || ''}&token=${localStorage.getItem('userToken')}`} target="_blank" className="button min">Clientes sin recolección</a>
            <a href={`${hostBase}/api/user/export/?type=appointments&status=c&months=${filter?.export || 1}&from=${dateFormat(filter?.from) || ''}&to=${dateFormat(filter?.to) || ''}&agent=${filter?.export_agent || ''}&token=${localStorage.getItem('userToken')}`} target="_blank" className="button min">Recolecciones</a>
        </div>
        <div className="w-50">
            <h2>
                Sin recolección <small>({tables?.clients?.length})</small>
                <select name="sin" value={filter?.sin || ''} onChange={updateTables}>
                    <option value="1">Mes actual</option>
                    <option value="2">Mes pasado</option>
                    <option value="3">Mes antepasado o más</option>
                </select>
                <select name="agent" value={filter?.agent || ''} onChange={updateTables}>
                    <option value="">TODOS</option>
                    {tables?.agents.map(a => <option key={a.agid} value={a.agid}>{a.name.split(' ').slice(0,2).join('')}</option>)}
                </select>
            </h2>
            <div className="overflow">
                <table>
                    <tbody>
                        {tables?.clients?.map((c, i) => <tr key={i}>
                            <th>{c.label}</th>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
        <div className="w-50">
            <h2>Clientes certificados al día</h2>
            <div className="overflow">
                <table>
                    <tbody>
                        {tables?.appointments.map((c, i) => <tr key={i}>
                            <th>{c.label}</th>
                            <td>{c.appointments}</td>
                        </tr>)}
                        <tr>
                            <th>TOTAL</th>
                            <td>{tables?.appointments.reduce((_total, c) => _total + +c.appointments, 0)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}
export default Dashboard